/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default function content() {
  return (
    <>
      <div className="site-wrap">
        <div className="site-container">
          <div className="site-content site-content-posts site-content-posts-large">
            <article>
              <time dateTime="2019-03-10T15:00">Updated: May 26, 2020</time>
              <h2>Privacy Policy</h2>

              <blockquote>
                <p>
                  Your privacy is important to us and we work very hard to keep
                  it safe, secure and locked. We do not read your account
                  contents.
                  <br />
                  <br />
                  No humans nor any computerized bots will ever read your
                  account data. We will never serve third-party advertisement
                  based on the algorithmic use of your account data.
                </p>
              </blockquote>
              <p>
                It is Optimum Futurist Tech Pvt. Ltd.'s policy and commitment to
                respect your privacy regarding any information we may collect
                while operating our web applications or website. Accordingly, we
                have developed this privacy policy in order for you to
                understand how we collect, use, communicate, disclose and
                otherwise make use of personal information. We have outlined our
                privacy policy below.
              </p>
              <ul className="lists">
                <li>
                  We will collect personal information by lawful and fair means
                  and, where appropriate, with the knowledge or consent of the
                  individual concerned.
                </li>
                <li>
                  Before or at the time of collecting personal information, we
                  will identify the purposes for which information is being
                  collected.
                </li>
                <li>
                  We will collect and use personal information solely for
                  fulfilling those purposes specified by us and for other
                  ancillary purposes, unless we obtain the consent of the
                  individual concerned or as required by law.
                </li>
                <li>
                  Personal data should be relevant to the purposes for which it
                  is to be used, and, to the extent necessary for those
                  purposes, should be accurate, complete, and up-to-date.
                </li>
                <li>
                  We will protect personal information by using reasonable
                  security safeguards against loss or theft, as well as
                  unauthorized access, disclosure, copying, use or modification.
                </li>
                <li>
                  We will make readily available to customers information about
                  our policies and practices relating to the management of
                  personal information.
                </li>
              </ul>
              <p>
                We are committed to conducting our business in accordance with
                these principles in order to ensure that the confidentiality of
                personal information is protected and maintained. Optimum
                Futurist Tech Pvt. Ltd. may change this privacy policy from time
                to time at Optimum Futurist Tech Pvt. Ltd.'s sole discretion.
              </p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}
